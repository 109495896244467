import styled from '@emotion/styled'
import { graphql, useStaticQuery } from 'gatsby'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import get from 'lodash/get'
import React, { useMemo } from 'react'

import { COLORS } from '@borrowell/bw-styles'

import mq from '../styles/breakpoints'

import { ENTER_KEY_CODE, SPACE_KEY_CODE } from '../constants'
import useWindowScroll from '../hooks/useWindowScroll'

import { Button } from '../components/Button'
import { Image } from '../components/Image'
import { Link } from '../components/Link'

import { usePathname } from '../context/PathnameProvider'
import { ILink } from '../graphql/modules/link'
import { IHeader } from '../graphql/sections/header'
import navbarCloseImage from '../images/navbar-close.svg'
import navbarOpenImage from '../images/navbar-open.svg'

interface IProps {
  data: {
    contentfulHeader: IHeader
    fixedLogo: {
      gatsbyImageData: IGatsbyImageData
    }
  }
}

const HeaderWrapper = styled.header<{ isScrolled: boolean }>`
  ${mq({
    position: 'fixed',
    width: '100%',
    zIndex: '100',
    backgroundColor: COLORS.WHITE,
  })}
  box-shadow: ${({ isScrolled }) => (isScrolled ? '0 10px 10px -10px rgba(115,113,111,0.15)' : '')};
  transition: box-shadow 0.2s ease-in-out;
`
const NavBarToggler = styled.div<{ navbarActive: boolean }>(({ navbarActive }) =>
  mq({
    display: 'flex',
    width: '50px',
    height: '50px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center left',
    backgroundImage: `url(${navbarActive ? navbarCloseImage : navbarOpenImage})`,
  }),
)

const DesktopHeader = styled.div`
  ${mq({
    display: ['none', 'none', 'flex'],
    flexDirection: ['row', '', 'column'],
    alignItems: ['', '', 'center'],
    justifyContent: ['', '', 'space-between'],
    padding: ['', '', '20px 60px'],
    maxWidth: '1440px',
    margin: 'auto',
  })}
`

const MobileAndTabletHeader = styled.div`
  ${mq({
    display: ['block', 'block', 'none'],
    height: '68px',
  })}
`

const CondensedHeader = styled.div`
  ${mq({
    display: ['flex', 'flex', 'none'],
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: ['9px 10px 9px 5px', '9px 35px 9px 35px', ''],
    width: '100%',
  })}
`

const DesktopTopSection = styled.div`
  ${mq({
    display: 'flex',
    flexDirection: ['', '', 'row'],
    alignItems: ['', '', 'center'],
    justifyContent: ['', '', 'space-between'],
    width: '100%',
    marginBottom: '0px',
  })}
`
const PageLinks = styled.div`
  ${mq({
    display: 'flex',
    flexDirection: ['', '', 'row'],
    alignItems: ['', '', 'center'],
    justifyContent: ['', '', 'flex-start'],
    flexWrap: ['', '', 'wrap'],
  })}
`

const Buttons = styled.div`
  ${mq({
    alignItems: ['center', 'inherit', 'inherit'],
    width: ['300px', '300px', '340px'],
    display: 'flex',
    flexDirection: ['column', 'column', 'row'],
    justifyContent: ['center', 'center', 'space-evenly'],
    alignSelf: ['', '', 'flex-start'],
  })}
`

const CompanyLogoWrapper = styled.div`
  ${mq({
    height: '24px',
    marginLeft: ['21px', '0px', '0px'],
  })}
`

const NavBarActive = styled.div`
  ${mq({
    display: ['block', 'block', 'none'],
    backgroundColor: 'white',
    // On some iPhones 100vh only brings you to the button save area
    height: ['120vh', '120vh', 'auto'],
    overflow: 'scroll',
  })}
`

const NavBar = styled.nav`
  ${mq({
    display: 'flex',
    flexDirection: ['column', 'column', 'row'],
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexWrap: ['', '', 'wrap'],
    // 18px + 2px from default button padding = 20px
    marginTop: ['', '', '18px'],
    // To compensate the 120vh height set below and to ensure buttons are visible upon scrolling
    paddingBottom: ['200px', '200px', '0px'],
  })}
`

const NavLinkList = styled.ul`
  ${mq({
    listStyle: 'none',
    display: 'flex',
    flexDirection: ['column', '', 'row'],
    alignItems: ['', '', 'center'],
    margin: 0,
  })}
`

const NavLinkListElement = styled.li`
  ${mq({
    display: 'flex',
    lineHeight: 1,
    margin: 0,
    ':first-of-type': {
      borderTop: [`solid 1px ${COLORS.NEUTRAL.COOL['100']}`, 'none', 'none'],
    },
  })}
`

const MobileButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: auto 5px auto auto;
`

const companyLogoStyles = {
  width: '106px',
  height: '24px',
}

const customLinkStyles = {
  display: ['flex', 'flex', 'initial'],
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: ['16px', '16px', '14px'],
  fontWeight: 'normal',
  lineHeight: ['3', '3', 'normal'],
  letterSpacing: '0.2px',
  color: COLORS.NEUTRAL.COOL['900'],
}

const customLoginButtonStyles = {
  width: ['314px', '', '162px'],
  height: ['44px', '', '36px'],
  borderRadius: ['22px', '', '18px'],
  border: [`solid 2px ${COLORS.PURPLE['700']}`, '', `solid 2px ${COLORS.PURPLE['700']}`],
  backgroundColor: 'white',
  fontSize: ['14px', '', '14px'],
  letterSpacing: ['0.2px', '', '0.2px'],
  fontWeight: ['bold', '', 'bold'],
  lineHeight: ['normal', '', 'normal'],
  margin: '0px',
  ':hover': {
    backgroundColor: COLORS.PURPLE['050'],
  },
}

const customSignupButtonStyles = {
  width: ['314px', '314px', '162px'],
  height: ['44px', '44px', '36px'],
  borderRadius: ['24px', '', '18px'],
  fontSize: '14px',
  letterSpacing: 'normal',
  fontWeight: 'bold',
  lineHeight: '1.86',
  margin: ['10px 0', '10px 0', '0px'],
}

const commonMobileButtonStyles = {
  width: '80px',
  height: '28px',
  borderRadius: '22px',
  padding: '20px',
  margin: 'auto',
  fontSize: '16px',
  letterSpacing: '0.2px',
  fontWeight: 'bold',
}

const customMobileHeaderLoginButtonStyles = {
  ...commonMobileButtonStyles,
  backgroundColor: COLORS.WHITE,
  color: COLORS.PURPLE['700'],
  ':hover': undefined,
  ':active': {
    backgroundColor: COLORS.WHITE,
  },
  ':visited': {
    backgroundColor: COLORS.WHITE,
  },
}

const customNavbarLinksStyles = {
  width: ['100vw', '100vw', 'auto'],
  margin: ['', '', '0 24px 0 0'],
  padding: 0,
}

const NavBarLinkList: React.FC<{
  navbarLinks: ILink[]
  setNavbarActive: (arg: boolean) => void
}> = ({ navbarLinks, setNavbarActive }) => {
  const pathname = usePathname()

  return (
    <NavLinkList>
      {navbarLinks.map((navbarItem, index) => {
        const linkText = get(navbarItem, 'linkText', '')
        const link = get(navbarItem, 'link', '')
        const linkRel = get(navbarItem, 'rel', '')
        const contentfulId = get(navbarItem, 'contentful_id', '')
        const typeName = get(navbarItem, '__typeName', '')

        const highlightStyles =
          pathname === link
            ? {
                ...customLinkStyles,
                color: COLORS.PURPLE['700'],
                fontWeight: 'bold',
              }
            : {
                ...customLinkStyles,
                '&:before': {
                  display: ['none', '', 'block'],
                  content: `"${linkText}"`,
                  fontWeight: 'bold',
                  height: '0',
                  overflow: 'hidden',
                  visibility: 'hidden',
                },
              }

        return (
          <NavLinkListElement key={index}>
            <Link
              to={link}
              name={linkText}
              text={linkText}
              rel={linkRel}
              onClick={() => setNavbarActive(false)}
              textStyles={highlightStyles}
              styleOverrides={customNavbarLinksStyles}
              contentfulId={contentfulId}
              typeName={typeName}
            />
          </NavLinkListElement>
        )
      })}
    </NavLinkList>
  )
}

export const Header: React.FC<IProps> = React.memo(
  ({
    data: {
      contentfulHeader: { login, signup, logoLink, navbar, company },
      fixedLogo: { gatsbyImageData: companyLogoImage },
    },
  }) => {
    const [navbarActive, setNavbarActive] = React.useState(false)
    const [isScrolled] = useWindowScroll()

    React.useEffect(() => {
      document.body.style.overflowY = navbarActive ? 'hidden' : 'scroll'
      return () => {
        document.body.style.overflowY = 'scroll'
      }
    }, [navbarActive])

    const {
      link: companyLogoLink,
      rel: companyLogoLinkRel,
      contentful_id: companyLogoContentfulId,
      __typename: companyLogoTypeName,
    } = logoLink
    const {
      link: loginButtonLink,
      linkName: loginButtonName,
      linkText: loginButtonText,
      rel: loginButtonRel,
      contentful_id: loginButtonContentfulId,
      __typename: loginButtonTypeName,
    } = login
    const {
      link: signupButtonLink,
      linkName: signupButtonName,
      linkText: signupButtonText,
      rel: signupButtonRel,
      contentful_id: signupButtonContentfulId,
      __typename: signupButtonTypeName,
    } = signup

    const { alternateText: companyLogoAltText } = company.logo.alternateText

    const handleToggle = (e?: React.MouseEvent) => {
      e?.preventDefault()
      setNavbarActive(!navbarActive)
    }

    const handleKeyDown = (event: React.KeyboardEvent) => {
      const keyCode = get(event, 'keyCode', '')

      if (keyCode === SPACE_KEY_CODE || keyCode === ENTER_KEY_CODE) {
        handleToggle()
      }
    }

    // Memoized Company Logo to avoid image flickering on scroll
    const companyLogoSection = useMemo(
      () => (
        <CompanyLogoWrapper>
          <Link
            name="company-logo"
            to={companyLogoLink}
            rel={companyLogoLinkRel}
            ariaLabel={companyLogoAltText}
            contentfulId={companyLogoContentfulId}
            typeName={companyLogoTypeName}
            withoutTextStyling
          >
            <Image fluid={companyLogoImage} alt={companyLogoAltText} customStyles={companyLogoStyles} />
          </Link>
        </CompanyLogoWrapper>
      ),
      [
        companyLogoLink,
        companyLogoLinkRel,
        companyLogoAltText,
        companyLogoContentfulId,
        companyLogoTypeName,
        companyLogoImage,
      ],
    )

    return (
      <HeaderWrapper isScrolled={isScrolled}>
        {/* Mobile and Tablet */}
        <MobileAndTabletHeader>
          <CondensedHeader>
            {companyLogoSection}
            <MobileButtonWrapper>
              <Button
                theme="TERTIARY"
                to={loginButtonLink}
                rel={loginButtonRel}
                name={loginButtonName}
                styleOverrides={customMobileHeaderLoginButtonStyles}
                contentfulId={loginButtonContentfulId}
                typeName={loginButtonTypeName}
              >
                {loginButtonText}
              </Button>
            </MobileButtonWrapper>
            <NavBarToggler
              onClick={handleToggle}
              onKeyDown={handleKeyDown}
              role="button"
              tabIndex={0}
              style={{ color: 'transparent' }}
              navbarActive={navbarActive}
            >
              {navbarActive ? 'Close' : 'Menu'}
            </NavBarToggler>
          </CondensedHeader>
          {navbarActive ? (
            <NavBarActive>
              <NavBar>
                <NavBarLinkList navbarLinks={navbar} setNavbarActive={setNavbarActive} />
                <Button
                  to={loginButtonLink}
                  rel={loginButtonRel}
                  name={loginButtonName}
                  styleOverrides={customLoginButtonStyles}
                  contentfulId={loginButtonContentfulId}
                  typeName={loginButtonTypeName}
                >
                  {loginButtonText}
                </Button>
                <Button
                  to={signupButtonLink}
                  rel={signupButtonRel}
                  name={signupButtonName}
                  styleOverrides={customSignupButtonStyles}
                  contentfulId={signupButtonContentfulId}
                  typeName={signupButtonTypeName}
                >
                  {signupButtonText}
                </Button>
              </NavBar>
            </NavBarActive>
          ) : null}
        </MobileAndTabletHeader>
        {/* Desktop */}
        <DesktopHeader>
          <DesktopTopSection>
            <PageLinks>{companyLogoSection}</PageLinks>
            <Buttons>
              <Button
                to={loginButtonLink}
                rel={loginButtonRel}
                name={loginButtonName}
                styleOverrides={customLoginButtonStyles}
                contentfulId={loginButtonContentfulId}
                typeName={loginButtonTypeName}
              >
                {loginButtonText}
              </Button>
              <Button
                to={signupButtonLink}
                rel={signupButtonRel}
                name={signupButtonName}
                styleOverrides={customSignupButtonStyles}
                contentfulId={signupButtonContentfulId}
                typeName={signupButtonTypeName}
              >
                {signupButtonText}
              </Button>
            </Buttons>
          </DesktopTopSection>
          <NavBar>
            <NavBarLinkList navbarLinks={navbar} setNavbarActive={setNavbarActive} />
          </NavBar>
        </DesktopHeader>
      </HeaderWrapper>
    )
  },
)

export const HeaderSection: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulHeader {
        ...HeaderFragment
        contentful_id
        __typename
      }
      fixedLogo: contentfulAsset(contentful_id: { eq: "22s6tSK0mU10xmisjWyK35" }) {
        gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
      }
    }
  `)

  return <Header data={data} />
}

export default HeaderSection
